import { isEmpty, isString } from "lodash";
import { KnownBlock } from "@slack/web-api";

import { ID, Person } from "@api/types";

import { toBlocks } from "@utils/slack";

import * as api from "./api";
export { setSlackAuth } from "./api";

type MessageFrom = { message: string | KnownBlock[]; author?: Person };

export const createThread = async (
  channel: ID,
  title: MessageFrom,
  body: MessageFrom
) => {
  const message = await api.createThread(
    channel,
    isString(title?.message) ? toBlocks(title?.message) : title?.message,
    title?.author
  );
  const parentId = message?.ts;

  if (!parentId) {
    throw new Error("Failed to create message.");
  }

  // If we don't have a reply in the thread then you can't deeplink into slack to the threadUI,
  // instead it just highlights the message and you would have to start typing your thread yourself....
  const firstReply = await api.replyThread(
    channel,
    parentId,
    isString(body?.message)
      ? toBlocks(body?.message)
      : !isEmpty(body?.message)
      ? body?.message
      : toBlocks("Thread started from Traction."),
    body?.author
  );

  const threadId = firstReply?.ts || parentId;
  const link = await api.getMessageLink(channel, threadId);

  return { link, message };
};

export const replyInThread = async (
  channel: ID,
  thread: ID,
  body: MessageFrom
) => {
  const message = await api.replyThread(
    channel,
    thread,
    isString(body?.message) ? toBlocks(body?.message) : body?.message,
    body.author
  );

  if (!message) {
    throw new Error("Failed to send message.");
  }

  const link = await api.getMessageLink(channel, message.ts || thread);

  return { link, message };
};
