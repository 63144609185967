import { filter, find, map } from "lodash";

import { Fn, using, fallback, until } from "@utils/fn";
import { when, maybeMap, Maybe } from "@utils/maybe";
import { fromNotionUrl, isPartial } from "@utils/notion";
import { asUUID, isUUID } from "@utils/id";
import { ifDo } from "@utils/logic";
import { isUrl } from "@utils/url";
import { ensureArray } from "@utils/array";

import {
  Database,
  DatabaseID,
  Entity,
  FilterQuery,
  ID,
  Link,
  Project,
  PropertyDef,
  PropertyRef,
  PropertyType,
  Task,
  Team,
  User,
  View,
} from "../../types";

import * as api from "./api";
import * as mappings from "./mappings";
import * as Notion from "./types";
import {
  Page,
  PageBlocks,
  PageOrDatabase,
  Database as NotionDatabase,
} from "./types";

export const getDatabasePropertyValues = async <
  E extends Entity,
  T extends PropertyType
>(
  dbId: DatabaseID,
  property: PropertyRef<E, T>
): Promise<PropertyDef<E, T>["values"]> => {
  const db = await api.getDatabase(dbId.scope);
  const keyMap = mappings.toKeyMap(dbId.type);
  const prop = db.properties[keyMap[property.field as keyof typeof keyMap]];

  if (prop.type === "relation") {
    const relatedValues = await api.queryDatabase({
      dbId: prop.relation.database_id,
    });

    // @ts-ignore
    return map(relatedValues.results, (v) =>
      mappings.toRelationRef(v as Notion.Page)
    );
  } else if (prop.type === "people") {
    const users = await api.queryUsers();

    // @ts-ignore
    return maybeMap(users.results, (v) =>
      v.type !== "bot" ? mappings.toPerson(v as Notion.User) : undefined
    );
  } else {
    return maybeMap(
      mappings.toPropertyValues<E, T>(prop),
      (p) => p.value
    ) as PropertyDef<E, T>["values"];
  }
};

export const getDatabase = async <T extends Entity>(
  dbId: DatabaseID
): Promise<Database<T>> => {
  const db = await api.getDatabase(dbId.scope);

  if (isPartial<Notion.Database, Notion.PartialDatabase>(db, "created_time")) {
    throw new Error("Partial db reponse not supported.");
  }

  return mappings.toDatabase(db);
};

export const getPageBody = async (
  pageId: ID,
  limit?: number
): Promise<PageBlocks> => api.getChildBlocks(pageId, limit);

export const getPageComments = (pageId: string, limit?: number) =>
  api.getPageComments(pageId, limit);

export const getNotionLocation = async (pageId: ID): Promise<Link[]> => {
  const { page, parents } = await api.getPageWithParents(pageId);
  return map([...parents, page], (p) => mappings.toLink(p as Notion.Page));
};

export const searchPages = (
  query: string,
  opts?: api.SearchOpts
): Promise<Page[]> =>
  until(
    () =>
      ifDo(isUrl(query) || isUUID(asUUID(query)), () =>
        when(asUUID(query) || fromNotionUrl(query) || undefined, async (id) => {
          try {
            const page = await api.getPage(id);
            return when(page, ensureArray) as Maybe<Page[]>;
          } catch (err) {
            // Do nothing
          }
        })
      ),

    () =>
      api
        .search(query, opts)
        ?.then((pages) => filter(pages, (p) => p.object === "page") as Page[])
  );

export const searchAll = (
  query: string,
  opts?: api.SearchOpts
): Promise<PageOrDatabase[]> =>
  until(
    () =>
      ifDo(isUrl(query) || isUUID(asUUID(query)), () =>
        when(fromNotionUrl(query) || asUUID(query) || undefined, async (id) => {
          try {
            const page = await api.getPageOrDatabase(id);
            return when(page, ensureArray) as Maybe<PageOrDatabase[]>;
          } catch (err) {
            // Do nothing
          }
        })
      ),

    () => api.search(query, opts) as Promise<PageOrDatabase[]>
  );

export const searchDatabases = (
  query: string,
  opts?: api.SearchOpts
): Promise<PageOrDatabase[]> =>
  until(
    () =>
      ifDo(isUrl(query) || isUUID(asUUID(query)), () =>
        when(fromNotionUrl(query) || asUUID(query) || undefined, async (id) => {
          try {
            const page = await api.getDatabase(id);
            return when(page, ensureArray) as Maybe<NotionDatabase[]>;
          } catch (err) {
            // Do nothing
          }
        })
      ),

    () => api.search(query, opts) as Promise<NotionDatabase[]>
  );
