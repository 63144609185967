import { Maybe, when } from "@utils/maybe";
import { isUrl } from "@utils/url";

import { ImageIcon } from "./image";
import { EmojiIcon } from "./emoji";

export const iconFromString = (icon: Maybe<string>) =>
  when(icon, (i) =>
    isUrl(i) ? <ImageIcon url={i} /> : <EmojiIcon emoji={i} />
  );
