import { useMemo } from "react";

import { Color } from "@api";

import { cx } from "@utils/class-names";
import { toColorVar } from "@utils/color";

import { Icon } from ".";
import { Check, CheckArrow, CheckIndeterminate } from "./svgs";

import styles from "./styles.module.css";

export type CheckedState = boolean | "indeterminate" | "moved";

interface Props {
  checked: CheckedState;
  color?: Color;
  className?: string;
}

export const CheckIcon = ({ checked, className, color = "blue" }: Props) => (
  <div
    style={useMemo(
      () => (checked ? { background: toColorVar(color) } : undefined),
      [color, checked]
    )}
    className={cx(
      styles.checkIcon,
      (checked === true || checked === "moved") && styles.checked,
      color && styles[color],
      checked === "indeterminate" && styles.indeterminate,
      className
    )}
  >
    {checked && (
      <Icon
        className={styles.check}
        icon={
          checked === true
            ? Check
            : checked === "indeterminate"
            ? CheckIndeterminate
            : CheckArrow
        }
      />
    )}
  </div>
);
