import { isArray } from "lodash";
import { CSSProperties } from "react";
import { BaseColor, Color, Shade } from "@api";

import { cx } from "@utils/class-names";
import { fromColor } from "@utils/color";
import { Fn } from "@utils/fn";

import styles from "./styles.module.css";

interface Props {
  color?: Color;
  className?: string;
  style?: CSSProperties;
  onClick?: Fn<React.MouseEvent, void>;
}

export const ColorIndicator = ({
  color = "gray",
  className,
  style,
  onClick,
}: Props) => (
  <span
    className={cx(styles.icon, styles.colorIndicator, className)}
    onClick={onClick}
    style={style}
  >
    <span className={cx(styles.color, styles[color])} />
  </span>
);

export const UnreadIndicator = ({ className }: { className?: string }) => (
  <ColorIndicator
    color="blue"
    className={cx(styles.unreadIndicator, className)}
  ></ColorIndicator>
);

interface SwatchProps {
  color: Color | [BaseColor, Shade];
  onClick?: Fn<React.MouseEvent, void>;
  className?: string;
}

export const Swatch = ({ color: _color, onClick, className }: SwatchProps) => {
  const [color, shade] = isArray(_color) ? _color : fromColor(_color);
  return (
    <span
      className={cx(styles.swatch, className)}
      onClick={onClick}
      style={{
        background:
          !shade || shade === 1
            ? `var(--color-user-${color})`
            : `var(--color-user-${color}-${shade})`,
      }}
    />
  );
};
