import {
  BlockObjectRequest,
  ChildPageBlockObjectResponse,
} from "@notionhq/client/build/src/api-endpoints";
import { Client } from "@notionhq/client";
import { NotionToMarkdown } from "notion-to-md";

import { onClient } from "@utils/ssr";
import { Maybe, when } from "@utils/maybe";
import { some } from "@utils/promise";

import { Block, ID, WorkspaceConfig } from "../../types";

import {
  DbFilter,
  DbSort,
  Comment,
  PageBlocks,
  PagePropertyInput,
  PageUpdateInput,
  ParentReference,
  RichTextInput,
  Page,
  ParentRef,
  Database,
  PageOrDatabase,
} from "./types";
import { toNotionUrl } from "@utils/notion";

export let client = new Client();
let authToken = "";

export const setNotionAuth = (ws: WorkspaceConfig) => {
  authToken = ws.auths?.notion?.user?.token || "";
  client = new Client({
    auth: authToken,
    baseUrl: onClient(() => `${window.location.origin}/api/proxy/notion`),
    fetch: (url, opts) => {
      return fetch(url, { ...opts, method: opts?.method?.toUpperCase() });
    },
  });
};

export const getNotionAuth = () => authToken;

export const getDatabase = async (id: ID) =>
  await client.databases.retrieve({
    database_id: id,
  });

export const queryUsers = async () => await client.users.list({});

export const queryDatabase = async (opts: {
  dbId: ID;
  filter?: DbFilter;
  sort?: DbSort;
  cursor?: string;
}) =>
  await client.databases.query({
    database_id: opts.dbId,
    start_cursor: opts.cursor,
    filter: opts.filter,
    sorts: opts.sort,
  });

export const getPage = (id: string) =>
  client.pages.retrieve({
    page_id: id,
  });

export const getPageAsMarkdown = async (id: string) => {
  const n2m = new NotionToMarkdown({
    notionClient: client,
    config: {
      separateChildPage: false,
      convertImagesToBase64: false,
      parseChildPages: true,
    },
  });
  // Not working...
  n2m.setCustomTransformer("child_page", async (block) => {
    const page = block as ChildPageBlockObjectResponse;
    return `[${page.id}](${toNotionUrl(page.id)})`;
  });
  const mdblocks = await n2m.pageToMarkdown(id);
  return n2m.toMarkdownString(mdblocks)?.parent || "";
};

export const updatePage = (id: string, changes?: PageUpdateInput) =>
  client.pages.update({
    page_id: id,
    ...changes,
  });

export const restorePage = (id: string) =>
  client.pages.update({
    page_id: id,
    archived: false,
  });

export const deletePage = (id: string) =>
  client.pages.update({
    page_id: id,
    archived: true,
  });

export const createPage = (
  parent: Maybe<ParentReference>,
  properties: PagePropertyInput = {},
  content: Array<BlockObjectRequest> = [],
  icon?: string
) => {
  // @ts-ignore
  return client.pages.create({
    // Can't create root files through API at the moment
    // @ts-ignore
    parent: parent ?? { workspace: true, type: "workspace" },
    properties: properties,
    icon: when(icon, (i) =>
      i.startsWith("http")
        ? { type: "external", external: { url: i } }
        : { type: "emoji", emoji: i as any }
    ),
    children: content,
  });
};

export const getBlock = async (blockId: ID): Promise<Block> => {
  const res = await client.blocks.retrieve({ block_id: blockId });
  return res as Block;
};

export const getChildBlocks = async (
  pageId: ID,
  limit: number = 1000
): Promise<PageBlocks> => {
  const res = await client.blocks.children.list({
    block_id: pageId,
    page_size: limit,
  });
  return res.results;
};

export const getPageComments = async (
  pageId: ID,
  limit: number = 1000
): Promise<Comment[]> => {
  const res = await client.comments.list({
    block_id: pageId,
    page_size: limit,
  });
  return res.results;
};

export const createPageComment = async (pageId: ID, comment: RichTextInput) =>
  client.comments.create({
    parent: { page_id: pageId, type: "page_id" },
    rich_text: comment,
  });

export const getPageOrDatabase = async (
  id: ID
): Promise<Maybe<PageOrDatabase>> => {
  const [page, db] = await some([getPage(id), getDatabase(id)]);
  return (page as Maybe<Page>) || (db as Maybe<Database>);
};

export interface SearchOpts {
  limit?: number;
  mode?: "page" | "database" | "all";
}

export const search = async (searchQuery: string, opts?: SearchOpts) => {
  const res = await client.search({
    query: searchQuery,
    page_size: opts?.limit || 8,
    filter:
      opts?.mode && ["database", "page"]?.includes(opts?.mode)
        ? {
            property: "object",
            value: opts.mode as "database" | "page",
          }
        : undefined,
  });
  return res.results;
};

export const getParent = (p: ParentRef) => {
  switch (p.type) {
    case "workspace":
      return undefined;
    case "page_id":
      return getPage(p.page_id) as Promise<Page>;
    case "block_id":
      return getBlock(p.block_id);
    case "database_id":
      return getDatabase(p.database_id) as Promise<Database>;
  }
};

const getParents = async (
  p: ParentRef,
  parents: (Page | Database)[] = []
): Promise<(Page | Database)[]> => {
  const parent = await getParent(p);
  // Don't add blocks to page hierarchy
  const res =
    parent && parent.object !== "block" ? [parent, ...parents] : parents;

  return (
    when((parent as { parent: ParentRef })?.parent, (next) =>
      getParents(next, res)
    ) || res
  );
};

export const getPageWithParents = async (id: string) => {
  const page = await getPageOrDatabase(id);
  const parentRef = page?.parent;

  if (!parentRef) {
    throw new Error("Unexpected partial page response.");
  }

  const parents = await getParents(parentRef, []);

  return { page, parents };
};
