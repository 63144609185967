import {
  filter as loFilter,
  every,
  find,
  isArray,
  map,
  pick,
  some,
  flatMap,
  filter,
} from "lodash";

import {
  Entity,
  FilterOperation,
  FilterQuery,
  Link,
  PersonRef,
  PropertyDef,
  PropertyRef,
  PropertyType,
  PropertyValue,
  RelationRef,
  SelectOption,
  SingleFilterQuery,
  Status,
} from "@api";

import { warn } from "./debug";
import { fallback, use } from "./fn";
import { ifDo, or, switchEnum } from "./logic";
import { isDefined, Maybe, maybeMap, SafeRecord, when } from "./maybe";
import { lowerSentenceCase } from "./string";
import { OneOrMany, ensureArray, ensureMany, overlaps } from "./array";
import { now } from "./now";
import {
  toLabel as toValueLabel,
  toKey as toValueKey,
  toPropertyValueRef,
  asValue,
  isEmptyRef,
} from "./property-refs";
import { evaluate } from "./formula";
import { toTextLike } from "./rich-text";
import { fromISO, toPointDate } from "./date-fp";

// undefined !== undefined
const equals = <T>(a: T, b: T) => !!a && !!b && a == b;
// something === !undefined, not(undefined, undefined) === true
const notEquals = <T>(a: T, b: T) => (!a && !b) || a !== b;

export const arrayOrOne = <T>(t: T | T[]) =>
  isArray(t) ? (t.length > 1 ? t : t[0]) : t;

export const isAnd = <T extends Entity>(
  f: FilterQuery<T>
): f is { and: FilterQuery<T>[] } => !!(f as { and: FilterQuery<T>[] })?.and;

export const isOr = <T extends Entity>(
  f: FilterQuery<T>
): f is { or: FilterQuery<T>[] } => !!(f as { or: FilterQuery<T>[] })?.or;

export function append(f: FilterQuery, f2: FilterQuery): FilterQuery;
export function append(f: FilterQuery, f2: Maybe<FilterQuery>): FilterQuery;
export function append(f: Maybe<FilterQuery>, f2: FilterQuery): FilterQuery;
export function append(
  f: Maybe<FilterQuery>,
  f2: Maybe<FilterQuery>
): Maybe<FilterQuery>;
export function append(
  f: Maybe<FilterQuery>,
  f2: Maybe<FilterQuery>
): Maybe<FilterQuery> {
  if (f && f2) {
    return {
      and: filter([f, f2], isDefined),
    };
  }

  return f || f2;
}

export const isNested = or(isAnd, isOr);

// Whether the filter is restricting results in any way
export const isValidFilter = (f: Maybe<FilterQuery>): boolean => {
  if (!f) {
    return false;
  }
  if (isOr(f)) {
    return some(f.or, isValidFilter);
  }

  if (isAnd(f)) {
    return some(f.and, isValidFilter);
  }

  return switchEnum(f.op, {
    is_empty: () => true,
    is_not_empty: () => true,
    else: () => f.value?.[f.type] !== undefined || !!f.values?.[f.type]?.length,
  });
};

// Is an _empty filter
export const isEmptyFilter = (f: Maybe<FilterQuery>) =>
  !f
    ? true
    : isOr(f)
    ? !f.or.length
    : isAnd(f)
    ? !f.and.length
    : f.op === "is_empty" || f.op === "is_not_empty";

export const toList = <T extends Entity>(f: FilterQuery<T>): FilterQuery<T>[] =>
  fallback(
    ifDo(isAnd(f), () => (f as { and: FilterQuery[] })?.and),
    () => ifDo(isOr(f), () => (f as { or: FilterQuery[] })?.or),
    () => [f as SingleFilterQuery]
  );

export const stringify = <T extends Entity>(
  maybeF: Maybe<FilterQuery<T>>
): string =>
  when(maybeF, (f) =>
    switchEnum(isAnd(f) ? "and" : isOr(f) ? "or" : "single", {
      and: () =>
        `(${map((f as { and: FilterQuery<T>[] }).and, stringify).join(
          " AND "
        )})`,
      or: () =>
        `(${map((f as { or: FilterQuery<T>[] }).or, stringify).join(" OR ")})`,
      else: () =>
        use(
          f as SingleFilterQuery,
          (filter) =>
            `${filter.field} ${toLabel(filter.op)} ${JSON.stringify(
              filter.value
            )}`
        ),
    })
  ) || "";

export const toCount = <T extends Entity>(f: FilterQuery<T>) =>
  toList(f)?.length || 0;

export const toFilterOption = <T extends Entity, P extends PropertyType>(
  def: PropertyDef<T, P>,
  value: PropertyValue[P]
): { id: string; name: string; value: PropertyValue } => {
  const propValue = asValue(
    def.type,
    switchEnum<PropertyType, Maybe<PropertyValue[PropertyType]>>(def.type, {
      links: () => when(value as OneOrMany<Link>, ensureArray),
      multi_select: () => when(value as OneOrMany<SelectOption>, ensureArray),
      relations: () => when(value as OneOrMany<RelationRef>, ensureArray),
      person: () => when(value as OneOrMany<PersonRef>, ensureArray),
      status: () =>
        find(def.values.status, (s) => s.id === (value as Status).id) || value,
      else: () => value,
    }) as PropertyValue[P]
  );
  const valueRef = { ...pick(def, "type", "field"), value: propValue };

  return {
    id: toValueKey(valueRef) ?? "",
    name: toValueLabel(valueRef) ?? "",
    value: propValue,
  };
};

export const toLabel = (op: FilterOperation) =>
  switchEnum(op, {
    does_not_equal: "is not",
    equals: "is",
    does_not_contain: "doesn't contain",
    contains: "contains",
    is_empty: "is empty",
    is_not_empty: "is not empty",
    else: () => lowerSentenceCase(op),
  });

export const evalFormulas = <T extends Entity>(
  filter: Maybe<FilterQuery<T>>
): Maybe<FilterQuery<T>> => {
  if (!filter) {
    return undefined;
  }

  if (isAnd(filter)) {
    return { and: maybeMap(filter.and, evalFormulas) };
  }

  if (isOr(filter)) {
    return { or: maybeMap(filter.or, evalFormulas) };
  }

  // If it's a date filter with a formula, evaluate it
  if (filter?.type === "date" && filter.value?.formula) {
    return {
      ...filter,
      value: {
        date: when(evaluate(filter.value.formula), toPointDate),
      },
    };
  }

  return filter;
};

export const passes = <T extends Entity>(
  thing: T,
  filter: FilterQuery<T>,
  defLookup: SafeRecord<string, PropertyDef<T>>
): boolean => {
  if (isAnd(filter)) {
    return every(filter.and, (f) => !!f && passes<T>(thing, f, defLookup));
  }

  if (isOr(filter)) {
    return some(filter.or, (f) => !!f && passes<T>(thing, f, defLookup));
  }

  const def = defLookup[filter?.field];
  const valueRef = toPropertyValueRef(thing, filter);

  // Incorrect filter/type match
  if (filter.type !== valueRef.type) {
    throw new Error("Incorrect filter type");
  }

  // Hack to allow workspace filter to always pass locally despite not being on client models
  // state/views/utils.ts :360
  if (filter.field === "workspace") {
    return true;
  }

  // Empty handling
  const isEmpty = isEmptyRef(valueRef);

  if (filter.op === "is_empty" || (filter.empty === true && isEmpty)) {
    return isEmpty;
  }

  if (filter.op === "is_not_empty") {
    return !isEmpty;
  }

  // Use values instead of value when defined for certain typoes, should pu
  const filterValue = switchEnum<
    PropertyType,
    PropertyValue[PropertyType] | Date
  >(filter.type, {
    select: () => filter.values?.select || filter.value?.select,
    multi_select: () =>
      filter.values?.multi_select || filter.value?.multi_select,
    relation: () => filter.values?.relation || filter.value?.relation,
    relations: () => filter.values?.relations || filter.value?.relations,
    status: () => {
      return flatMap(
        filter.values?.status || when(filter.value?.status, ensureMany),
        (status) =>
          !!status?.group
            ? loFilter(
                def?.values?.status || [],
                (s) => s.group === status?.group || s.id == status?.id
              )
            : status
      );
    },
    date: () =>
      when(filter.value?.date, fromISO) ||
      when(filter.value?.formula, evaluate),
    else: () => filter.value?.[filter.type],
  });

  // Filter not finished setup
  if (
    !isDefined(filterValue) ||
    (isArray(filterValue) && filterValue.length === 0)
  ) {
    return true;
  }

  switch (filter.type) {
    case "number": {
      const fVal = filter.value?.number ?? 0;
      const tVal = valueRef.value.number;
      return switchEnum(filter.op, {
        equals: () => equals(tVal, fVal),
        does_not_equal: () => notEquals(tVal, fVal),
        // If value ref not set then false, else math op
        greater_than: () => !!tVal && tVal > fVal,
        // If value ref not set then false, else math op
        less_than: () => !!tVal && tVal < fVal,
        else: () => {
          warn(`Number does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "boolean": {
      const fVal = filter.value?.boolean ?? 0;
      const tVal = valueRef.value.boolean;
      return switchEnum(filter.op, {
        equals: () => equals(tVal, fVal),
        does_not_equal: () => notEquals(tVal, fVal),
        else: () => {
          warn(`Number does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "rich_text": {
      const fVal = toTextLike(filter.value?.[filter.type]) ?? "";
      const tVal = toTextLike(valueRef.value[filter.type]);

      return switchEnum(filter.op, {
        equals: () => equals(tVal, fVal),
        does_not_equal: () => notEquals(tVal, fVal),
        contains: () => !!tVal && tVal.indexOf(fVal) > -1,
        does_not_contain: () => (tVal || "")?.indexOf(fVal) === -1,
        starts_with: () => !!tVal && tVal.startsWith(fVal),
        ends_with: () => !!tVal && tVal.endsWith(fVal),
        else: () => {
          warn(`Text does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "text":
    case "email":
    case "title": {
      const fVal = filter.value?.[filter.type] ?? "";
      const tVal = valueRef.value[filter.type];

      return switchEnum(filter.op, {
        equals: () => equals(tVal, fVal),
        does_not_equal: () => notEquals(tVal, fVal),
        contains: () => !!tVal && tVal.indexOf(fVal) > -1,
        does_not_contain: () => (tVal || "")?.indexOf(fVal) === -1,
        starts_with: () => !!tVal && tVal.startsWith(fVal),
        ends_with: () => !!tVal && tVal.endsWith(fVal),
        else: () => {
          warn(`Text does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "date": {
      const fVal = filterValue as Date;
      const tVal = fromISO(valueRef.value.date);
      return switchEnum(filter.op, {
        equals: () => equals(tVal, fVal),
        does_not_equal: () => notEquals(tVal, fVal),
        after: () => !!tVal && tVal > fVal,
        before: () => !!tVal && tVal < fVal,
        on_or_after: () => !!tVal && tVal >= fVal,
        on_or_before: () => !!tVal && tVal <= fVal,
        else: () => {
          warn(`Date does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "select":
    case "status": {
      const fVal = (filterValue || []) as SelectOption | SelectOption[];
      const tVal = valueRef.value[filter.type];

      if (!isDefined(fVal)) {
        return true;
      }

      if (isArray(fVal)) {
        return switchEnum(filter.op, {
          equals: () =>
            some(
              fVal,
              (fVal) =>
                equals(fVal?.id, tVal?.id) || equals(fVal?.name, tVal?.name)
            ),
          does_not_equal: () =>
            every(fVal, (fVal) => notEquals(fVal?.id, tVal?.id)),
          else: () => {
            warn(`Select & status does not support "${filter.op}" operation.`, {
              valueRef,
              thing,
              filter,
            });
            return false;
          },
        });
      }

      return switchEnum(filter.op, {
        equals: () =>
          equals(fVal.id, tVal?.id) || equals(fVal.name, tVal?.name),
        does_not_equal: () =>
          notEquals(fVal.id, tVal?.id) &&
          (!fVal?.id || notEquals(fVal?.name, tVal?.name)),
        else: () => {
          warn(`Select & status does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "multi_select": {
      const fVal = map(
        (filter.values || filter.value)?.multi_select,
        (v) => v.id
      );
      const tVal = map(valueRef.value[filter.type] || [], (v) => v.id);

      return switchEnum(filter.op, {
        equals: () => overlaps(fVal, tVal),
        does_not_equal: () => !overlaps(fVal, tVal),
        contains: () => overlaps(fVal, tVal),
        does_not_contain: () => !overlaps(fVal, tVal),
        else: () => {
          warn(`Multi-select does not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }

    case "person":
    case "relation":
    case "relations": {
      const fVal = map(
        ensureArray(
          filter.values?.[filter.type] || filter.value?.[filter.type]
        ),
        (v) => v.id
      );
      const tVal = map(
        ensureArray(valueRef.value[filter.type]) || [],
        (v) => v.id
      );

      return switchEnum(filter.op, {
        equals: () => overlaps(fVal, tVal),
        does_not_equal: () => !overlaps(fVal, tVal),
        contains: () => overlaps(fVal, tVal),
        does_not_contain: () => !overlaps(fVal, tVal),
        else: () => {
          warn(`Relations do not support "${filter.op}" operation.`, {
            valueRef,
            thing,
            filter,
          });
          return false;
        },
      });
    }
  }

  return false;
};

export const availableOps = (
  type: SingleFilterQuery["type"]
): SingleFilterQuery["op"][] => {
  switch (type) {
    case "text":
    case "rich_text":
    case "title":
    case "email":
    case "url":
    case "phone":
      return [
        "equals",
        "does_not_equal",
        "contains",
        "does_not_contain",
        "starts_with",
        "ends_with",
        "is_empty",
        "is_not_empty",
      ];

    case "boolean":
      return ["equals", "does_not_equal"];

    case "number":
      return [
        "greater_than",
        "less_than",
        "equals",
        "is_empty",
        "is_not_empty",
      ];

    case "date":
      return [
        "equals",
        "does_not_equal",
        // "on_or_after",
        // "on_or_before",
        "after",
        "before",
        "is_empty",
        "is_not_empty",
      ];

    case "select":
    case "status":
      return ["equals", "does_not_equal", "is_empty", "is_not_empty"];

    case "person":
    case "relation":
    case "relations":
    case "multi_select":
      return ["equals", "does_not_equal", "is_empty", "is_not_empty"];

    default:
      return [];
  }
};

export const defaultValueForFilter = (
  filter: Omit<SingleFilterQuery, "value">
) => {
  switch (filter.op) {
    case "is_empty":
    case "is_not_empty":
      return true;
  }

  switch (filter.type) {
    case "text":
    case "title":
    case "rich_text":
      return "";

    case "date":
      return now();

    default:
      return undefined;
  }
};

export const defaultOpForProp = (p: Partial<PropertyRef>): FilterOperation => {
  switch (p.type) {
    case "text":
    case "title":
    case "rich_text":
      return "contains";

    case "date":
      return "after";

    case "select":
    case "status":
      return "equals";

    case "multi_select":
    case "person":
    case "relation":
      return "equals";

    default:
      return "equals";
  }
};
