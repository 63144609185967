import { find, isFunction, reduce, reduceRight } from "lodash";

import { Maybe, Nullable } from "./maybe";
import { now } from "./now";

export type Pred<T> = (t: T) => boolean;
export type Fn<T, R> = (t: T) => R;
export type FnA<A extends Array<any>, R> = (...a: A) => R;

export const otherwise = <T>(_?: T) => true;
export const defined = <T>(t: Nullable<T>): t is T => !!t;
export const not =
  <T>(p: Pred<T>): Pred<T> =>
  (t) =>
    !p(t);

export function until<R>(
  f5: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f4: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f3: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f2: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f1: Fn<void, Promise<R> | R>
): Promise<R>;
export function until<T1, T2, T3, T4, R>(
  f4: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f3: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f2: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f1: Fn<void, Promise<R> | R>
): Promise<R>;
export function until<T1, T2, T3, R>(
  f3: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f2: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f1: Fn<void, Promise<R> | R>
): Promise<R>;
export function until<T1, T2, R>(
  f2: Fn<void, Promise<Nullable<R>> | Nullable<R>>,
  f1: Fn<void, Promise<R> | R>
): Promise<R>;
export function until<R>(...fns: Fn<void, Promise<R>>[]) {
  return reduce(
    fns,
    async (
      v: Promise<Nullable<R>> | Nullable<R>,
      fn: Fn<void, Promise<Nullable<R>> | Nullable<R>>
    ) => (await v) || fn(),
    undefined
  );
}

export function fallback<R>(
  f13: Nullable<R> | Fn<void, Nullable<R>>,
  f12: Nullable<R> | Fn<void, Nullable<R>>,
  f11: Nullable<R> | Fn<void, Nullable<R>>,
  f10: Nullable<R> | Fn<void, Nullable<R>>,
  f9: Nullable<R> | Fn<void, Nullable<R>>,
  f8: Nullable<R> | Fn<void, Nullable<R>>,
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f12: Nullable<R> | Fn<void, Nullable<R>>,
  f11: Nullable<R> | Fn<void, Nullable<R>>,
  f10: Nullable<R> | Fn<void, Nullable<R>>,
  f9: Nullable<R> | Fn<void, Nullable<R>>,
  f8: Nullable<R> | Fn<void, Nullable<R>>,
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f10: Nullable<R> | Fn<void, Nullable<R>>,
  f9: Nullable<R> | Fn<void, Nullable<R>>,
  f8: Nullable<R> | Fn<void, Nullable<R>>,
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f9: Nullable<R> | Fn<void, Nullable<R>>,
  f8: Nullable<R> | Fn<void, Nullable<R>>,
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f8: Nullable<R> | Fn<void, Nullable<R>>,
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f7: Nullable<R> | Fn<void, Nullable<R>>,
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f6: Nullable<R> | Fn<void, Nullable<R>>,
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f5: Nullable<R> | Fn<void, Nullable<R>>,
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f4: Nullable<R> | Fn<void, Nullable<R>>,
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f3: Nullable<R> | Fn<void, Nullable<R>>,
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(
  f2: Nullable<R> | Fn<void, Nullable<R>>,
  f1: Nullable<R> | Fn<void, R>
): R;
export function fallback<R>(...fns: (Nullable<R> | Fn<void, R>)[]) {
  return reduce(
    fns,
    (v: Nullable<R>, fn: Nullable<R> | Fn<void, Nullable<R>>) =>
      v ?? (isFunc(fn) ? fn() : fn),
    undefined
  );
}

export function use<T1, R>(a: T1, fn: (t: T1) => R): R {
  return fn(a);
}

export function using<T1, T2, T3, T4, T5, R>(
  a: [T1, T2, T3, T4, T5],
  fn: (...args: [T1, T2, T3, T4, T5]) => R
): R;
export function using<T1, T2, T3, T4, R>(
  a: [T1, T2, T3, T4],
  fn: (...args: [T1, T2, T3, T4]) => R
): R;
export function using<T1, T2, T3, R>(
  a: [T1, T2, T3],
  fn: (...args: [T1, T2, T3]) => R
): R;
export function using<T1, T2, R>(a: [T1, T2], fn: (...args: [T1, T2]) => R): R;
export function using<T1, A extends [T1], R>(a: A, fn: (...args: A) => R): R;
export function using<A extends [], R>(a: A, fn: (...args: A[]) => R) {
  return fn(...a);
}

export const guard =
  <T, R>(guards: [Pred<T>, Fn<T, R>][]): Fn<T, R> =>
  (ts) => {
    const guard = find(guards, ([guard]) => guard(ts));
    if (!guard) {
      throw new Error("No matching guards found.");
    }
    return guard[1](ts);
  };

export { pipe as composel, curry, partial, __ } from "lodash/fp";

export const id =
  <T>(t: T) =>
  () =>
    t;
export const _ = id;

export function composelAsync<T1, T2, T3, T4, T5, R>(
  f1: Fn<T1, Promise<T2> | T2>,
  f2: Fn<T2, Promise<T3> | T3>,
  f3: Fn<T3, Promise<T4> | T4>,
  f4: Fn<T4, Promise<T5> | T5>,
  f5: Fn<T5, Promise<R> | R>
): (a?: T1) => Promise<R>;
export function composelAsync<T1, T2, T3, T4, R>(
  f1: Fn<T1, Promise<T2> | T2>,
  f2: Fn<T2, Promise<T3> | T3>,
  f3: Fn<T3, Promise<T4> | T4>,
  f4: Fn<T4, Promise<R> | R>
): (a?: T1) => Promise<R>;
export function composelAsync<T1, T2, T3, R>(
  f1: Fn<T1, Promise<T2> | T2>,
  f2: Fn<T2, Promise<T3> | T3>,
  f3: Fn<T3, Promise<R> | R>
): (a?: T1) => Promise<R>;
export function composelAsync<T1, T2, R>(
  f1: Fn<T1, Promise<T2> | T2>,
  f2: Fn<T2, Promise<R> | R>
): (a?: T1) => Promise<R>;
export function composelAsync<T1, R>(...fns: Fn<any, Promise<any>>[]) {
  return async (a?: T1): Promise<R> =>
    reduce(fns, async (v, fn) => fn(await v), a as any);
}

export function composeAsync<T1, T2, T3, T4, T5, R>(
  f5: Fn<T5, Promise<R> | R>,
  f4: Fn<T4, Promise<T5> | T5>,
  f3: Fn<T3, Promise<T4> | T4>,
  f2: Fn<T2, Promise<T3> | T3>,
  f1: Fn<T1, Promise<T2> | T2>
): (a?: T1) => Promise<R>;
export function composeAsync<T1, T2, T3, T4, R>(
  f4: Fn<T4, Promise<R> | R>,
  f3: Fn<T3, Promise<T4> | T4>,
  f2: Fn<T2, Promise<T3> | T3>,
  f1: Fn<T1, Promise<T2> | T2>
): (a?: T1) => Promise<R>;
export function composeAsync<T1, T2, T3, R>(
  f3: Fn<T3, Promise<R> | R>,
  f2: Fn<T2, Promise<T3> | T3>,
  f1: Fn<T1, Promise<T2> | T2>
): (a?: T1) => Promise<R>;
export function composeAsync<T1, T2, R>(
  f2: Fn<T2, Promise<R> | R>,
  f1: Fn<T1, Promise<T2> | T2>
): (a?: T1) => Promise<R>;
export function composeAsync<T1, R>(...fns: Fn<any, Promise<any>>[]) {
  return async (a?: T1): Promise<R> =>
    reduceRight(fns, async (v, fn) => fn(await v), a as any);
}

export const isFunc = isFunction;

const EMPTY = "Em_pTy";

const createCache = <F extends (...args: any) => any>(maxAge: number) => {
  const state = {} as Record<string, [number, ReturnType<F>]>;

  return {
    getOr(key: string): ReturnType<F> | typeof EMPTY {
      if (!(key in state)) {
        return EMPTY;
      }

      const [ts, value] = state[key];
      if (now().getTime() - ts > maxAge) {
        delete state[key];
        return EMPTY;
      }

      return value;
    },
    set(key: string, value: ReturnType<F>) {
      state[key] = [now()?.getTime(), value];
    },
  };
};

export const simpleSerializer = (args: any[]): string =>
  reduce(args, (r, a) => r + String(a), "");

export const cachedFunc = <R, F extends (...args: any) => R>(
  func: F,
  milliseconds: number,
  normalizer: (args: Parameters<F>) => string = simpleSerializer
): F => {
  const cache = createCache(milliseconds);

  return ((...args: Parameters<F>) => {
    const key = normalizer(args);
    const value = cache.getOr(key);

    if (value !== EMPTY) {
      return value;
    }

    const newValue = func(...args);
    cache.set(key, newValue);
    return newValue;
  }) as F;
};

export const lazyCachedFunc = <R, A extends any[], F extends (...args: A) => R>(
  getFunc: Fn<void, F>,
  milliseconds: number,
  normalizer?: (args: Parameters<F>) => string
): F => {
  let func: Maybe<F> = undefined;
  return ((...args: Parameters<F>) => {
    if (!func) {
      func = cachedFunc(getFunc(), milliseconds, normalizer);
    }
    return func(...args);
  }) as F;
};
