import { switchEnum } from "@utils/logic";

import { Update, Entity, EntityType, ID } from "../../types";

import { toBlocks, toRichTextInput } from "@utils/notion";
import { Maybe, when } from "@utils/maybe";
import { omitEmpty } from "@utils/object";

import * as api from "./api";
import * as mappings from "./mappings";
import * as Notion from "./types";
import { RichTextInput } from "./types";

export const persistNew = async <T extends Entity>(
  update: Extract<Update<T>, { method: "create" }>
) => {
  if (update.source.source === "notion") {
    const page = await api.createPage(
      { database_id: update.source.scope },
      mappings.toPageProperties(update.changes, update.source)
    );

    return { id: page.id };
  }

  throw new Error("Unsupported update integration.");
};

export const persistUpdate = async <T extends Entity>(
  update: Extract<Update<T>, { method: "update" }>
) => {
  if (update.source.source === "notion") {
    return api.updatePage(update.id, {
      properties: mappings.toPageProperties(update.changes, update.source),
    });
  }

  throw new Error("Unsupported update integration.");
};

export const persistRestore = async <T extends Entity>(
  update: Extract<Update<T>, { method: "restore" }>
) => {
  if (update.source.source === "notion") {
    const restored = await api.restorePage(update.id);

    return switchEnum<EntityType, Entity>(update.source.type, {
      project: () => mappings.toProject(restored as Notion.Page),
      task: () => mappings.toTask(restored as Notion.Page),
      view: () => mappings.toView(restored as Notion.Page),
      else: () => {
        throw new Error("Unknown entity to restore.");
      },
    });
  }

  throw new Error("Unsupported update integration.");
};

export const persistDelete = async <T extends Entity>(
  update: Extract<Update<T>, { method: "delete" }>
) => {
  if (update.source.source === "notion") {
    return api.deletePage(update.id);
  }

  throw new Error("Unsupported update integration.");
};

export const persistComment = async (pageId: ID, comment: RichTextInput) =>
  api.createPageComment(pageId, comment);

export const createPage = async (
  parent: Maybe<Notion.ParentReference>,
  title: string,
  markdown?: string,
  icon?: string
) => {
  const page = await api.createPage(
    parent,
    { title: toRichTextInput(title) },
    when(markdown, toBlocks) as any,
    icon
  );
  return { id: page.id };
};

export const updatePage = async (
  id: string,
  changes: Partial<{ title: string; icon: string }>
) => {
  const page = await api.updatePage(id, {
    properties: omitEmpty({
      title: when(changes.title, (t) => ({
        title: toRichTextInput(t),
        type: "title",
      })),
    }),
    ...when(changes.icon, (i) => ({
      icon: { type: "emoji", emoji: i as any },
    })),
  });
  return { id: page.id };
};
