import { CSSProperties, ReactNode, useMemo } from "react";

import { Color, isPerson, isTeam, Person, Team, Workspace } from "@api";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";
import { fromColor, toColorVar, toInverseColorVar } from "@utils/color";
import { use } from "@utils/fn";
import { CompanyFilled, EyeSlash } from "./svgs";

import styles from "./team.module.css";
import { Icon } from ".";

type TeamProps = {
  team: Maybe<Team>;
  className?: string;
  style?: CSSProperties;
};

type Props = {
  color: Maybe<Color>;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

export const SquareIcon = ({
  children,
  color,
  className,
  style: _style,
}: Props) => {
  const style = useMemo(
    () =>
      color
        ? use(fromColor(color), ([color, shade]) => ({
            ..._style,
            backgroundColor: toColorVar(color, shade),
            color: toInverseColorVar(color, shade),
          }))
        : _style,
    [_style, color]
  );
  return (
    <span className={cx(styles.team, className)} style={style}>
      {children}
    </span>
  );
};

export const TeamIcon = ({ team, className, style }: TeamProps) => {
  return (
    <SquareIcon
      color={team?.color || "gray_5"}
      className={className}
      style={style}
      children={team?.name.substring(0, 1) || <EyeSlash />}
    />
  );
};

export const SpaceIcon = ({
  entity,
}: {
  entity: Maybe<Team | Person | Workspace>;
}) =>
  isTeam(entity) ? (
    <TeamIcon team={entity} />
  ) : isPerson(entity) ? (
    <SquareIcon color="gray_5">
      <Icon icon={EyeSlash} />
    </SquareIcon>
  ) : (
    <SquareIcon color="gray_5">
      <Icon icon={CompanyFilled} />
    </SquareIcon>
  );
