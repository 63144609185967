import { CSSProperties } from "react";
import { cx } from "@utils/class-names";
import styles from "./styles.module.css";

export const EmojiIcon = ({
  emoji,
  className,
  style,
}: {
  emoji: string;
  className?: string;
  style?: CSSProperties;
}) => (
  <span className={cx(styles.emoji, className)} style={style}>
    {emoji}
  </span>
);
