import { isFunction, reduce } from "lodash";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Fn } from "./fn";
import { Maybe } from "./maybe";

export type SetStateFn<T> = Dispatch<SetStateAction<T>>;

export type ComponentOrNode =
  | React.ReactElement
  | React.FunctionComponent<any>
  | null
  | false;

export const isComponent = <T, O>(
  c: React.ComponentType<T> | O
): c is React.FunctionComponent<T> => isFunction(c);

export const useArrayKey = <T>(
  arr: Maybe<T[]>,
  toKey: Fn<T, string> = String
) => {
  return useMemo(
    () => reduce(arr, (acc, item) => acc + toKey(item), ""),
    [arr]
  );
};
