import { Maybe, Primitive } from "./maybe";

export const fixed = (n: number, length: number = 2) =>
  n.toString().padStart(length, "0");

export const asNumber = (v: Primitive): Maybe<number> => {
  const n = Number(v);
  return isNaN(n) ? undefined : n;
};

export const orZero = (v: Maybe<number>) => v || 0;
