import { isString } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Maybe } from "./maybe";
import { onClient } from "./ssr";
import { isLocalID } from "./id";

export const isUrl = (url: string) =>
  url.startsWith("https://") || url.startsWith("http://");

export const toParams = (params: Record<string, string>) =>
  new URLSearchParams(params).toString();

export const withParams = (url: string, params?: Record<string, string>) =>
  params ? url + (url?.includes("?") ? "&" : "?") + toParams(params) : url;

export const withoutParams = (url: string) => url.split("?")[0];

export const useSyncPathnameID = (tempId: string, savedId: Maybe<string>) => {
  const location = useLocation();

  useEffect(() => {
    if (!savedId || isLocalID(savedId)) {
      return;
    }

    const path = location.pathname;
    if (path && path.includes(tempId) && !path.includes(savedId)) {
      window.history.replaceState(
        undefined,
        "",
        location.pathname.replace(tempId, savedId)
      );
    }
  }, [savedId]);
};

export const redirect = (url: string | URL, newTab: boolean = false) =>
  onClient(() => {
    if (newTab) {
      window.open(url, newTab ? "_blank" : undefined);
    } else {
      window.location.href = isString(url) ? url : url.toString();
    }
  });

export const openInNewTab = (url: string) => redirect(url, true);

export const replacePath = (url: string, newPath: string) => {
  const u = new URL(url);
  return u.origin + newPath;
};

// REgex for matching the host of a url
const hostRegex = /^(https?:\/\/)?(www\.)?([^\/]+)/;
export const toPath = (url: string) => url?.replace(hostRegex, "");

export const toOrigin = (url: string) => {
  try {
    const u = new URL(url);
    return u.origin;
  } catch (err) {
    return undefined;
  }
};

export const toDomain = (url: string) =>
  toOrigin(url)
    ?.replace("https://", "")
    ?.replace("http://", "")
    ?.replace("www.", "");

// function that trims both leading and trailing slashes
const trailingSlash = /\/+$/; // regex for trimming one or more trailing slash
const leadingSlash = /^\/+/; // regex for trimming one or emore leading slash
export const trimSlashes = (str: string) =>
  str.replace(trailingSlash, "").replace(leadingSlash, "");

export const isImage = (url: string) =>
  url?.match(/\.(jpeg|jpg|gif|png)$/) != null;

export const isExternal = (url: string) =>
  !["traction.team", "dev.traction.team", "localhost"]?.includes(
    toDomain(url) || ""
  );
