import { findLast } from "lodash";
import axios from "axios";
import { Page, getPage } from "./integrations/notion";

import { getByIds, isError, toTitleOrName } from "@api";

import { fromNotionUrl, toTitle } from "@utils/notion";
import { maybe, Maybe, when } from "@utils/maybe";
import { isNotion, isSlack, isTraction } from "@utils/link";
import { log } from "@utils/debug";
import { toChannelId, toThreadId } from "@utils/slack";
import { isHumanId } from "@utils/id";
import { toOrigin, toPath } from "@utils/url";
import { now } from "@utils/now";

import { getMessage } from "./integrations/slack/api";
export { getPageBody, getPageComments } from "./integrations/notion/queries";
export * from "./integrations/traction/queries";

import { HasIcon, JsonObject } from "./types";
import { getLinkMeta } from "./queries";

export const getUrlMeta = async (
  url: string
): Promise<{
  text?: string;
  url: string;
  icon?: string;
  confidence?: number;
}> => {
  if (isTraction(url)) {
    try {
      // Extract last traction ID from params
      const entityId = findLast(toPath(url)?.split("/"), (p) => isHumanId(p));
      const [entity] = (await when(entityId, (id) => getByIds([id]))) || [];

      if (entity && !isError(entity)) {
        return {
          text: toTitleOrName(entity),
          url: url,
          icon: (entity as Maybe<HasIcon>)?.icon,
          confidence: 1,
        };
      }
    } catch (e) {
      log(e);
    }
  }

  if (isNotion(url)) {
    try {
      const page = (await when(fromNotionUrl(url), (id) =>
        getPage(id)
      )) as Maybe<Page>;
      if (page) {
        return {
          text: toTitle(page),
          url: url,
          icon:
            page.icon?.type === "emoji"
              ? page.icon.emoji
              : page?.icon?.type === "external"
              ? page?.icon?.external?.url
              : undefined,
          confidence: 1,
        };
      }
    } catch (e) {
      log(e);
    }
  }

  if (isSlack(url)) {
    try {
      const channel = toChannelId(url);
      const thread = toThreadId(url);
      const message = await when(
        channel && thread ? [channel, thread] : undefined,
        ([channel, thread]) => getMessage(channel, thread)
      );

      if (message) {
        return {
          text: message?.text?.replace("\n", "\r\n"),
          url: url,
          confidence: 1,
        };
      }
    } catch (e) {
      log(e);
    }
  }

  try {
    const res = await getLinkMeta(url);

    if (!res?.link) {
      throw new Error("Invalid web url.");
    }

    return { ...res.link, confidence: maybe(res.confidence) };
  } catch (err) {
    log(err);
  }

  return { url, text: toOrigin(url), icon: undefined, confidence: 0 };
};

export const getPromptResponse = async (prompt: string) => {
  const message = { content: prompt, role: "system", createdAt: now() };
  const { data } = await axios({
    method: "POST",
    url: "/api/chat/data",
    data: { messages: [message] },
  });
  return data?.content as string | JsonObject;
};
