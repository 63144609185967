import { Ref, forwardRef, CSSProperties, ReactNode } from "react";
import { cx } from "@utils/class-names";
import styles from "./styles.module.css";
import { Fn } from "@utils/fn";

export const CSSIcon = forwardRef(
  (
    {
      className,
      children,
      style,
      onClick,
    }: {
      className?: string;
      style?: CSSProperties;
      children?: ReactNode;
      onClick?: Fn<React.MouseEvent, void>;
    },
    ref: Ref<HTMLSpanElement>
  ) => (
    <span
      ref={ref}
      onClick={onClick}
      className={cx(styles.icon, className)}
      style={style}
    >
      {children}
    </span>
  )
);
